
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    const supplier_data = ref<Array<Supplier>>([]);
    const individual_customer_data = ref<Array<Supplier>>([]);
    const dialogCreateOfferTableVisible = ref(false);
    const expeceted_delivery_data = ref([]);

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 6,
    };

    const offerData: any = ref({
      sales_inquiry_details_id: "",
      offer_qty: "",
      incoterm_id: "",
      delivery_term_id: "",
      payment_terms_id: "",
      offer_remark: "",
      offer_rate: "",
      offer_amount: "",
      offer_total_amount: ref<any>(""),
      total_amount: ref<any>(""),
      offer_round_off_amount: ref<any>(""),
      offer_grand_total_amount: ref<any>(""),
      uom_name: "MT",
      is_igst_applicable: 0,
      igst_amount: ref<any>(""),
      cgst_amount: ref<any>(""),
      sgst_amount: ref<any>(""),
      required_qty: "",
      make_company_id: "",
      plant_id: "",
      brand_id: "",
      product_name: "",
    });

    var tableData = ref<Array<WIJournal>>([]);
    const dialogSelectLocationTableVisible = ref(false);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const make_company_data = ref([]);
    const productChargeData: any = ref([]);
    const selectedEnquiry: any = ref({});

    const incoterms_data = ref([]);
    const payment_term_data = ref([]);
    const plant_data = ref([]);
    const brand_list = ref([]);

    const formRef = ref<null | HTMLFormElement>(null);
    const offerFormRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getIncoterms();
    getPaymentTerms();
    getDeliveryTerms();
    getProductChargeList();

    interface WIJournal {
      sales_inquiry_no: string;
      sales_inquiry_details_id: string;
      sales_inquiry_id: string;
      product_name: string;
      uom_name: string;
      required_qty: string;
      make: Record<string, string>[];
      product_descritpion: Record<string, string>[];
      delivery_address: Record<string, string>[];
      remark: string;
    }

    interface Offer {
      sales_offer_id: string;
      manufacturer_company_name: string;
      required_qty: string;
      offer_qty: string;
      offer_time: string;
      uom_name: string;
      offer_remark: string;
      offer_date: string;
    }


    const formData = ref({
      client_select: "",
      individual_customer_select: "",
      customer_type: 1,
    });


    const rules = ref({
      client_select: [
        {
          required: true,
          message: "Client is required",
          trigger: "change",
        },
      ],
      individual_customer_select: [
        {
          required: true,
          message: "Client is required",
          trigger: "change",
        },
      ],
    });

    const offerRules = ref({
      incoterm_id: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      delivery_term_id: [
        {
          required: true,
          message: "Deliveryterm is required",
          trigger: "change",
        },
      ],
      payment_terms_id: [
        {
          required: true,
          message: "Deliveryterm is required",
          trigger: "change",
        },
      ],
      // make_company_id: [
      //   {
      //     required: true,
      //     message: "Manufacturer is required",
      //     trigger: "change",
      //   },
      // ],
      // plant_id: [
      //   {
      //     required: true,
      //     message: "Plant is required",
      //     trigger: "change",
      //   },
      // ],
      offer_qty: [
        {
          required: true,
          message: "Offer quantity is required",
          trigger: "change",
        },
      ],
      offer_rate: [
        {
          required: true,
          message: "Offer rate is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      let user = JSON.parse(JwtService.getToken());

      let productOtherCharges = productChargeData.value.map((item) => {
        return {
          charge_id: item.charge_id,
          charge_rate: item.rate,
          charge_taxable_amount: item.totalAmount,
          charge_tax: item.igstAmount,
          charge_total_amount: item.total,
        };
      });

      let requestBody = {
        se_detail_id: selectedEnquiry.value.sales_inquiry_details_id,
        mfg_company_id: data.make_company_id,
        mfg_plant_id: data.plant_id,
        brand_id: data.brand_id,
        required_quantity: data.required_qty,
        offered_quantity: data.offer_qty,
        product_rate_offer: data.offer_rate,
        product_taxable_amount: data.offer_amount,
        product_tax: data.igst_amount,
        product_total: data.offer_total_amount,
        product_other_charges_offer: productOtherCharges,
        total_amount: data.total_amount,
        round_up_amount: data.offer_round_off_amount,
        grand_total_amount: data.offer_grand_total_amount,
        payment_term_id: data.payment_terms_id,
        incoterm_id: data.incoterm_id,
        delivery_term_id: data.delivery_term_id,
        offer_remark: data.offer_remark,
        user_id: user.user_id,
      };
      console.log("finalBody", requestBody);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALES_OFFER, requestBody)
          .then(({ data }) => {
            loading.value = false;
            if (data?.se_offer_id) {
              setTimeout(() => {
                Swal.fire({
                   html: ""+

                      data?.se_offer_id+

                      "<br>Sales Offer created successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  dialogCreateOfferTableVisible.value = false;
                  router.push({ name: "apps-sales-offer-listing" });
                });
              }, 2000);
            } else {
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const submitOffer = async () => {
      loading.value = true;

      if (!offerFormRef.value) {
        loading.value = false;
        return;
      }

      offerFormRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(offerData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getIncoterms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_INCOTERM)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_DELIVERY_TERM)
        .then(({ data }) => {
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPaymentTerms() {
      await store
        .dispatch(Actions.CUST_GET_PAYMENT_TERM)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getPlants = async () => {
      let values = {
        company_branch_id: 0,
        company_id: offerData.value.make_company_id,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          plant_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getBrands = async () => {
      let values = {
        company_branch_id: 0,
        company_id: offerData.value.make_company_id,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brand_list.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getIndividualCustomerList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = {
        contact_id: 0,
        page: 1,
        records_per_page: 10,
        search_term: query,
      };
      await store
        .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST, values)
        .then(({ data }) => {
          debugger;
          individual_customer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getTableData = async (data) => {
      try {
        var values = {
          customer_type: formData.value.customer_type,
          customer_company_id:
            formData.value.customer_type == 1
              ? formData.value["client_select"]
              : 0,
          customer_contact_id:
            formData.value.customer_type == 0
              ? formData.value.individual_customer_select
              : 0,
        };
        console.log(values);
        await store
          .dispatch(
            ActionsFi.CUST_GET_PENDING_SALES_INQUIRY_LISTING_DATA,
            values
          )
          .then((data) => {
            debugger;
            if (data.status === 200) {
              console.log("total data:" + JSON.stringify(data));

              debugger;
              tableData.value = [];
              data = data.data;

              var resultsM = ref<Array<WIJournal>>([]);
              var status_label = "";
              var status_color = "";
              var active_label = "";
              var active_color = "";

              for (let j = 0; j < data.length; j++) {
                resultsM.value = Array({
                  sales_inquiry_no: data[j]["se_no"],
                  sales_inquiry_details_id: data[j]["se_detail_id"],
                  sales_inquiry_id: data[j]["se_id"],
                  product_name: data[j]["category_name"],
                  uom_name: data[j]["uom"],
                  required_qty: data[j]["required_quantity"],
                  make: data[j]["mfg_company_json"],
                  product_descritpion: data[j]["product_desc"],
                  delivery_address: data[j]["delivery_address"],
                  remark: data[j]["remark"],
                });
                console.log("resultsm:" + JSON.stringify(resultsM.value));
                tableData.value.splice(
                  j,
                  resultsM.value.length,
                  ...resultsM.value
                );
              }
            } else {
              tableData.value = [];
            }
          })
          .catch(({ response }) => {
            tableData.value = [];
          });
      } catch (e) {
        console.log(e);
      }
    };

    const createOffer = (selectedSalesEnquiry) => {
      let offerQty = selectedSalesEnquiry.required_qty;
      offerData.value.required_qty = selectedSalesEnquiry.required_qty;
      offerData.value.offer_qty = parseFloat(offerQty);
      selectedEnquiry.value = selectedSalesEnquiry;
      make_company_data.value = selectedSalesEnquiry?.make;
      getProductChargeList();
      dialogCreateOfferTableVisible.value = true;
    };

    const openDilogue = () => {
      dialogSelectLocationTableVisible.value = true;
    };

    const onCompnyTypeSelct = (selectedType) => {
      formData.value.customer_type = selectedType;
      tableData.value = [];
    };

    async function getProductChargeList() {
      try {
        await store
          .dispatch(ActionsFi.CUST_GET_QUOTATION_PRODUCT_CHARGE_LIST)
          .then(({ data }) => {
            if (data?.length) {
              let newChargeData = data.map((item, index) => {
                (item.rate = ""),
                  (item.totalAount = ""),
                  (item.igstTax = ""),
                  (item.igstAmount = ""),
                  (item.total = "");
                return item;
              });
              productChargeData.value = newChargeData;
            }
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }

    const calculateTableData = (row) => {
      console.log("rowName", row);
      let totalAmount = 0;
      let chargedTax = 0;
      let total = 0;
      if (!/^\d*\.?\d*$/.test(row.rate)) {
        row.rate = row.rate.slice(0, -1);
        return;
      }
      let offerQty = !isNaN(parseFloat(offerData.value.offer_qty))
        ? parseFloat(offerData.value.offer_qty)
        : 0;
      let rate = !isNaN(parseFloat(row.rate)) ? parseFloat(row.rate) : 0;

      totalAmount = offerQty * rate;
      row.totalAmount = totalAmount.toFixed(2).toString();
      row.tax="18%";
      chargedTax = (totalAmount * 18) / 100;
      row.igstAmount = chargedTax.toFixed(2).toString();
      total = totalAmount + chargedTax;
      row.total = total.toFixed(2).toString();
      calculateTableDataTotal();
      console.log("updatedrow", row);
      console.log("tableData", productChargeData);
    };

    const calculateTableDataTotal = () => {
      let grandTotal = 0;
      let rowTotal = 0;
      let roundUp = 0;
      for (let row of productChargeData.value as any) {
        rowTotal = parseFloat(row.total) ? parseFloat(row.total) : 0;
        grandTotal = rowTotal + grandTotal;
      }
      offerData.value.total_amount = (
        grandTotal + parseFloat(offerData.value.offer_total_amount)
      ).toFixed(2);

      roundUp = Math.round(offerData.value.total_amount);
      offerData.value.offer_grand_total_amount = roundUp.toFixed(2);

      offerData.value.offer_round_off_amount = (
        parseFloat(offerData.value.total_amount) - roundUp
      ).toFixed(2);
    };

    const calculateFirstRow = (field) => {
      let appliedTax = 0;

      if (!/^\d*\.?\d*$/.test(offerData.value[field])) {
        offerData.value[field] = offerData.value[field].slice(0, -1);
        return;
      } else {
        let offerAmount =
          parseFloat(offerData.value.offer_qty) *
          parseFloat(offerData.value.offer_rate);
        offerData.value.offer_amount = !isNaN(offerAmount)
          ? offerAmount.toFixed(2)
          : 0;
        offerData.value.tax = '18%';
        appliedTax = (18 * parseFloat(offerData.value.offer_amount)) / 100;
        offerData.value.igst_amount = appliedTax.toFixed(2);
        offerData.value.offer_total_amount = (
          parseFloat(appliedTax.toString()) +
          parseFloat(offerData.value.offer_amount)
        ).toFixed(2);

        calculateTableDataTotal();
      }
    };

    const resetForm = () => {
      offerData.value = {
        sales_inquiry_details_id: "",
        offer_qty: "",
        incoterm_id: "",
        delivery_term_id: "",
        offer_remark: "",
        offer_rate: "",
        offer_amount: "",
        offer_total_amount: ref<any>(""),
        total_amount: ref<any>(""),
        offer_round_off_amount: ref<any>(""),
        offer_grand_total_amount: ref<any>(""),
        uom_name: "MT",
        is_igst_applicable: 0,
        igst_amount: ref<any>(""),
        cgst_amount: ref<any>(""),
        sgst_amount: ref<any>(""),
        required_qty: "",
        make_company_id: "",
        tax:""
      };
      productChargeData.value = [];
      dialogCreateOfferTableVisible.value = false;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Salse Offer", []);
    });

    return {
      formData,
      rules,
      submit,
      submitOffer,
      formRef,
      offerFormRef,
      loading,
      incoterms_data,
      payment_term_data,
      plant_data,
      brand_list,
      tableData,
      getCompanyList,
      getTableData,
      supplier_data,
      individual_customer_data,
      dialogSelectLocationTableVisible,
      multipleTableRef,
      openDilogue,
      expeceted_delivery_data,
      paginationData,
      offerData,
      dialogCreateOfferTableVisible,
      createOffer,
      onCompnyTypeSelct,
      offerRules,
      make_company_data,
      productChargeData,
      calculateTableData,
      calculateTableDataTotal,
      calculateFirstRow,
      selectedEnquiry,
      getIndividualCustomerList,
      getPlants,
      getBrands,
      resetForm,
    };
  },
});
